import React, { useEffect, useRef } from "react";
import { useField } from "@unform/core";

interface ChildrenPropsComponent
  extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: string;
}

const Input: React.FC<ChildrenPropsComponent> = ({ name, label, ...rest }) => {
  const inputRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: (ref) => {
        return ref.current.value;
      },
      setValue: (ref, value) => {
        ref.current.value = value;
      },
      clearValue: (ref) => {
        ref.current.value = "";
      },
    });
  }, [fieldName, registerField]);
  return (
    <>
      {label && <label className="my-4 block">{label}</label>}
      <input
        name={name}
        ref={inputRef}
        defaultValue={defaultValue}
        {...rest}
        className={[
          rest.className,
          `${
            error ? ["border", "border-red-300", "bg-red-100"].join(" ") : ""
          }`,
        ].join(" ")}
      />
    </>
  );
};
export { Input };
