import React, { useEffect, useRef } from "react";
import { useField } from "@unform/core";

interface ChildrenPropsComponent
  extends React.InputHTMLAttributes<HTMLTextAreaElement> {
  name: string;
}

const TextArea: React.FC<ChildrenPropsComponent> = ({ name, ...rest }) => {
  const inputRef = useRef(null);
  const { fieldName, defaultValue, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: (ref) => {
        return ref.current.value;
      },
      setValue: (ref, value) => {
        ref.current.value = value;
      },
      clearValue: (ref) => {
        ref.current.value = "";
      },
    });
  }, [fieldName, registerField]);
  return (
    <textarea
      name={name}
      ref={inputRef}
      defaultValue={defaultValue}
      className="outline-none focus:ring-2 focus:ring-black focus:border-transparen p-4 mt-4 bg-gray-100 w-full transition-all duration-500 rounded-lg resize-none"
      {...rest}
    />
  );
};
export { TextArea };
