import React from "react";
import { FormHandles } from "@unform/core";

import { Input } from "../Input";
interface ParentPropsComponent {
  readonly formRef?: React.RefObject<FormHandles>;
}

interface ChildrenPropsComponent {}

type BrasilApiCep = {
  cep: string;
  city: string;
  neighborhood: string;
  service: string;
  state: string;
  street: string;
};

const Address: React.FC<ChildrenPropsComponent & ParentPropsComponent> = ({
  formRef,
  children,
}) => {
  // const areas = [
  //   { label: "Centro", value: "centro" },
  //   { label: "Centro histórico", value: "centro-historico" },
  //   { label: "Centro-Sul", value: "centro-Sul" },
  //   { label: "Leste 1", value: "leste-1" },
  //   { label: "Leste 2", value: "leste-2" },
  //   { label: "Nordeste", value: "nordeste" },
  //   { label: "Noroeste", value: "noroeste" },
  //   { label: "Norte", value: "norte" },
  //   { label: "Oeste", value: "oeste" },
  //   { label: "Sudeste", value: "sudeste" },
  //   { label: "Sudoeste", value: "sudoeste" },
  //   { label: "Sul", value: "sul" },
  // ];

  const classInput = `outline-none focus:ring-2 focus:ring-black focus:border-transparen p-4 mt-4 bg-gray-100 w-full rounded-lg transition-all duration-300`;

  async function getCepViaBrasilApi(e: React.FocusEvent<HTMLInputElement>) {
    const cep = e.target.value.toString();

    if (cep.length >= 8) {
      const request = await fetch(`https://brasilapi.com.br/api/cep/v1/${cep}`);
      const response = (await request.json()) as BrasilApiCep;

      if (formRef) {
        formRef.current?.setData({
          ...formRef.current?.getData(),
          ...response,
        });
      }
    }
  }

  return (
    <div>
      <div className="md:flex md:justify-start w-full">
        <Input
          name="federalDoc"
          className={`${classInput}`}
          placeholder="CPF (opcional)"
          type="number"
          autoComplete={"off"}
        />
      </div>
      <div className="md:flex md:justify-start w-full">
        <Input
          name="name"
          className={`${classInput} md:mr-4`}
          placeholder="Seu nome"
          type="text"
          autoComplete={"off"}
        />
        <Input
          name="phone"
          className={`${classInput}`}
          placeholder="Seu telefone"
          type="tel"
          autoComplete={"off"}
        />
      </div>
      <div className="md:flex md:justify-start w-full">
        <Input
          name="cep"
          className={`${classInput} md:w-40 md:mr-4`}
          placeholder="CEP - ex: 21360080"
          type="number"
          maxLength={9}
          autoComplete={"off"}
          onBlur={(e) => getCepViaBrasilApi(e)}
        />
        <Input
          name="street"
          className={`${classInput} md:mr-4`}
          placeholder="Rua/Av"
          type="text"
          autoComplete={"off"}
        />
        <Input
          name="number"
          className={`${classInput} md:w-40`}
          placeholder="N°"
          type="number"
          autoComplete={"off"}
        />
      </div>
      {/* <div className="md:flex md:justify-start w-full">
        <Select name="area" options={areas} defaultValue={"selected"} />
      </div> */}
      <div className="md:flex md:justify-start w-full">
        <Input
          name="neighborhood"
          className={`${classInput} md:w-48 md:mr-4`}
          placeholder="Bairro"
          type="text"
          autoComplete={"off"}
        />
        <Input
          name="complement"
          className={`${classInput} w-full`}
          placeholder="Complemento"
          type="text"
          autoComplete={"off"}
        />
      </div>
      <div className="md:flex md:justify-start w-full">
        <Input
          name="city"
          className={`${classInput}  md:mr-4`}
          placeholder="Cidade"
          type="text"
          autoComplete={"off"}
        />
        <Input
          name="state"
          className={`${classInput} md:w-40`}
          placeholder="RJ"
          type="text"
          autoComplete={"off"}
        />
      </div>
      <div className="md:flex md:justify-start w-full">{children}</div>
    </div>
  );
};

export { Address };
