import { useParams } from "react-router";
import useSWR from "swr";
import api from "../services/api.service";

function useFetch<Data = any, Error = any>(url: string) {
  const { store_id } = useParams<{ store_id: string }>();

  const fetcher = async (url: string) => {
    const response = await api.get(url, {
      headers: {
        ...api.defaults.headers,
        "x-license-referenced": store_id,
      },
    });
    return response.data;
  };

  const { data, error } = useSWR<Data, Error>(url, fetcher, {
    revalidateOnFocus: false,
    refreshInterval: 60000,
    revalidateOnMount: true,
  });

  return { data, error };
}

export { useFetch };
