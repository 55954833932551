import React from "react";

interface ChildrenComponentProps
  extends React.ImgHTMLAttributes<HTMLImageElement> {
  name: string;
}

const Image: React.FC<ChildrenComponentProps> = ({ name, alt, ...rest }) => {
  if (!name) return <></>;

  return (
    <img
      src={
        process.env.NODE_ENV === "production"
          ? `https://node50164-csbase.jelastic.saveincloud.net/images/${name}`
          : `http://localhost:3030/images/${name}`
      }
      alt={alt}
      {...rest}
    />
  );
};

export { Image };
