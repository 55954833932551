import localForage from "localforage";

type TypeLocalStorageService = {
  create: (key: string, value: any) => Promise<any>;
  find: (key: string) => Promise<unknown>;
  remove: (key: string) => void;
  update: (
    key: string,
    value: any,
    opts?: { new: boolean }
  ) => Promise<boolean | any>;
};

class LocalStorageService implements TypeLocalStorageService {
  private readonly name = "@csmenu";
  private readonly storage: LocalForage;
  constructor(private readonly prefix: string) {
    this.storage = localForage.createInstance({
      driver: localForage.LOCALSTORAGE,
      name: this.name,
      version: 1.0,
      storeName: this.prefix,
    });
  }

  public async create(key: string, value: any) {
    return this.storage.setItem(key, value);
  }

  public find(key: string) {
    return this.storage.getItem(key);
  }
  public async remove(key: string) {
    await this.storage.removeItem(key);
  }
  public async update(key: string, value: any, opts?: { new: boolean }) {
    await this.remove(key);
    await this.create(key, value);
    return Promise.resolve(opts?.new ? value : true);
  }
}

export default LocalStorageService;
