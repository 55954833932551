/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";

import { useHistory, useParams } from "react-router-dom";

import { FormHandles } from "@unform/core";

import { Address, FormBuilder, Input, TextArea } from "../../components";
import { useDataStorage } from "../../hooks/datalstorage.hook";
import { useCurrency } from "../../hooks/curreny.hook";
import { MdRemoveCircle } from "react-icons/md";
import api from "../../services/api.service";
import {
  createOrderByDelivery,
  createOrderByTakeAway,
  createOrderByTable,
} from "./validation";
import { useToasts } from "react-toast-notifications";
import { useFetch } from "../../hooks/fetch.hook";
import { filter, findIndex, find } from "lodash";
import { move } from "../../util/array";

type BrasilApiCep = {
  cep: string;
  city: string;
  neighborhood: string;
  service?: string;
  state: string;
  street: string;
  complement?: string;
  area: string;
  number: string;
};

type DataForm = {
  items?: any[];
  customer?: {
    name: string;
    phone: string;
    federalDoc: string;
  };
  floorNumber?: string;
  address?: BrasilApiCep;
  deliveryType?: DeliveryOption;
  obs?: string;
  paymentOption?: PaymentOption;
  change?: string;
  dates: {
    delivered: string;
  };
};
type DeliveryOption = "delivery" | "take-away" | "table";
type PaymentOption = "credit" | "debit" | "money";
type DeliveryMenuOptions = {
  option: DeliveryOption;
  label: string;
};
type Product = {
  _id: string;
  price: number;
  name: string;
  image: string;
  description: string;
};
const Cart: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const formDelivery = useRef<HTMLDivElement>(null);

  const history = useHistory();
  const dataStorage = useDataStorage("cart");
  const dataOrdersStorage = useDataStorage("orders");
  const userStorage = useDataStorage("user");

  const { addToast } = useToasts();

  const currency = useCurrency();
  const { store_id } = useParams<{ store_id: string }>();

  const [deliveryOptionActive, setDeliveryOption] = useState<DeliveryOption>();
  const [paymentOptionActive, setPaymentOption] = useState<PaymentOption>();

  const [items, setCart] = useState<any[]>([]);
  const [, setOrders] = useState<any[]>([]);
  const [error, setError] = useState(false);

  const { data: store } = useFetch(`store/${store_id}`);
  const { data: products } = useFetch<Product[]>("product");

  useEffect(() => {
    document.title = `${store?.fantasy_name} - Carrinho de compras`;

    const loaderCartItems = async () => {
      const itemsCart = ((await dataStorage.find("items")) || []) as any[];
      const productsOnlyFees = filter(products, (product) =>
        product.name.toLowerCase().includes("taxa")
      );

      const itemIndexFee = () =>
        findIndex(itemsCart, (item) =>
          item.name.toLowerCase().includes("taxa")
        );

      if (itemIndexFee() === -1 && productsOnlyFees.length > 0) {
        itemsCart.push({ ...productsOnlyFees[0], qty: 1 });
        await dataStorage.update("items", [...itemsCart]);
        return setCart(
          move(itemsCart, itemIndexFee(), itemsCart.length - 1) || []
        );
      }

      if (itemIndexFee() > -1) {
        return setCart(
          move(itemsCart, itemIndexFee(), itemsCart.length - 1) || []
        );
      }
      setCart(itemsCart || []);
    };
    const loaderOrders = async () => {
      const orders = (await dataOrdersStorage.find("list")) as any[];
      setOrders(orders || []);
    };

    loaderCartItems();
    loaderOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const loaderFormData = async () => {
      const dataForm = (await userStorage.find("orderData")) as any;
      if (dataForm) formRef.current?.setData({ ...dataForm });
    };

    if (formDelivery) {
      formDelivery.current?.scrollIntoView({
        behavior: "smooth",
      });
      loaderFormData();
    }
  }, [deliveryOptionActive]);

  const deliveryOptions: DeliveryMenuOptions[] = [
    {
      option: "delivery",
      label: "Entregar para mim",
    },
    {
      option: "take-away",
      label: "Vou retirar",
    },
    // {
    //   option: "table",
    //   label: "Pedido na mesa",
    // },
  ];

  const paymentOptions = [
    {
      label: "Cartão - Credito",
      option: "credit",
    },
    {
      label: "Cartão - Debito",
      option: "debit",
    },
    {
      label: "Dinheiro",
      option: "money",
    },
  ];

  async function handleRemoveItemCart(item: any) {
    const { _id: itemId } = item;
    const newItemList = [...items.filter((item) => itemId !== item._id)];

    if (
      find(newItemList, (item) => item.name.toLowerCase().includes("taxa")) &&
      newItemList.length === 1
    )
      return handleClearCart();

    await dataStorage.update("items", newItemList);
    setCart([...newItemList]);
  }

  async function handleClearCart() {
    await dataStorage.remove("items");
    setCart([]);
  }

  function toggleOptionDelivery(deliveryOption: DeliveryMenuOptions) {
    return setDeliveryOption(deliveryOption.option);
  }

  function toggleOptionPayment(paymentOption: any) {
    setPaymentOption(paymentOption.option);
  }

  async function handleFormSubmit(data: any) {
    const {
      cep,
      city,
      neighborhood,
      state,
      street,
      name,
      phone,
      floorNumber,
      obs,
      complement,
      area,
      number,
      federalDoc,
      change,
      deliveryDateTime,
    } = data;

    let order: DataForm = {
      customer: {
        name,
        phone,
        federalDoc,
      },
      floorNumber,
      obs,
      address: {
        area,
        cep,
        city,
        neighborhood,
        state,
        street,
        complement,
        number,
      },
      deliveryType: deliveryOptionActive,
      paymentOption: paymentOptionActive,
      change,
      dates: {
        delivered: deliveryDateTime,
      },
    };

    if (paymentOptionActive !== "money") {
      delete order.change;
    }

    if (deliveryOptionActive === "take-away") {
      delete order.address;
      delete order.floorNumber;
      const formData = await createOrderByTakeAway(formRef, {
        ...data,
        paymentOptionActive,
      });
      setError(!!formData.erros);
      if (formData.erros) return false;
    }
    if (deliveryOptionActive === "delivery") {
      delete order.floorNumber;
      if (order.address) order.address.area = "whatsapp";
      const formData = await createOrderByDelivery(formRef, {
        ...data,
        paymentOptionActive,
      });
      setError(!!formData.erros);
      if (formData.erros) return false;
    }

    if (deliveryOptionActive === "table") {
      delete order.customer;
      delete order.address;
      const formData = await createOrderByTable(formRef, data);
      setError(!!formData.erros);
      if (formData.erros) return false;
    }

    order.items = items;

    return handleSendOrder(order);
  }

  async function handleSendOrder(order: DataForm) {
    const { data: dataOrder } = await api.post("/order/create", order, {
      headers: {
        "x-license-referenced": store_id,
      },
    });
    await updateOrders(dataOrder, order);
    await handleClearCart();
    addToast(`Seu pedido foi enviado com sucesso.`, {
      appearance: "success",
      autoDismiss: true,
    });
    history.push(`/${store_id}`);
  }

  async function updateOrders(orderData: any, orderForm: DataForm) {
    const { _id: orderId } = orderData;

    const order = {
      ...orderData,
      ...orderForm,
      dates: {
        ...orderData.dates,
        ...orderForm.dates,
        created: orderData.dates.created,
      },
      items: orderForm.items?.map((item) => {
        return {
          name: item.name,
          image: item.image,
          price: item.price,
          qty: item.qty,
          sku: item.sku,
          url: item.url,
          _id: item._id,
          codes: item.codes,
        };
      }),
    };

    const orders = ((await dataOrdersStorage.find("list")) as any[]) || [];

    const newItemList = [
      ...orders.filter((order) => orderId !== order._id),
      order,
    ];
    await dataOrdersStorage.update("list", newItemList);
    setOrders([...newItemList]);
  }

  async function saveDataForm() {
    const data = formRef.current?.getData() as any;
    data.obs = null;
    await userStorage.update("orderData", data);
  }

  return (
    <>
      <div className="md:flex md:justify-between container mx-auto p-4">
        <div className="flex flex-col w-full md:max-w-md md:mx-auto">
          <div className="md:max-w-md md:mx-4 mb-4">
            {items.length === 0 ? (
              <>
                <p className="text-center">Seu carrinho esta vazio.</p>
                <button
                  onClick={() => history.push(`/${store_id}`)}
                  className="py-4 px-8 text-black font-bold rounded  focus:outline-none hover:opacity-80 w-full"
                >
                  <span>Inicio</span>
                </button>
              </>
            ) : (
              <>
                <h1 className="font-bold text-xl mb-4">Resumo</h1>
                {items.map((item, index) => (
                  <div
                    key={index + 1}
                    className="flex justify-between p-4 mb-4 rounded shadow relative"
                  >
                    {!item.name.toLowerCase().includes("taxa") && (
                      <button
                        onClick={() => handleRemoveItemCart(item)}
                        className="focus:outline-none absolute -top-1 -right-1 w-18 h-18 rounded-full  text-red-500"
                        title={`Remover ${item.name}`}
                      >
                        <MdRemoveCircle size={24} />
                      </button>
                    )}

                    <h1 className="w-9/12 ">
                      <span className="block font-bold">
                        {!item.name.toLowerCase().includes("taxa") && (
                          <>{item.qty}x </>
                        )}
                        {item.name.length > 30
                          ? item.name.substr(0, 28) + "..."
                          : item.name}
                        {item.name.toLowerCase().includes("taxa") && (
                          <>
                            <br />
                            <small
                              className="text-xs font-normal"
                              style={{ fontSize: 10 }}
                            >
                              * Valor sujeito a alteração
                            </small>
                          </>
                        )}
                      </span>
                      {!item.name.toLowerCase().includes("taxa") && (
                        <span>Subtotal</span>
                      )}
                    </h1>
                    <div className="text-green-600">
                      <span className="block font-bold">
                        {currency.format(item.price)}
                      </span>
                      {!item.name.toLowerCase().includes("taxa") && (
                        <span className="font-bold">
                          {currency.format(item.price * item.qty)}
                        </span>
                      )}
                    </div>
                  </div>
                ))}
              </>
            )}
            {items.length > 0 && deliveryOptionActive !== "table" && (
              <div className="mt-4 py-4 rounded">
                {/* {deliveryOptionActive === "delivery" && (
                  <div className="flex justify-between mb-4">
                    <h1 className="w-8/12 ">Entrega</h1>
                    <span className="text-green-600 font-bold">R$ 3,50</span>
                  </div>
                )} */}

                <div className="flex justify-between">
                  <h1 className="w-8/12">Total</h1>
                  <span className="text-green-600 font-bold">
                    {currency.format(
                      items
                        .map((item) => item.price * item.qty)
                        .reduce((a, b) => a + b, 0)
                    )}
                  </span>
                </div>
              </div>
            )}
            {items.length > 0 && (
              <div className="flex justify-between">
                <button
                  onClick={() => history.push(`/${store_id}`)}
                  className="py-4 px-8 text-green-500 font-bold rounded  focus:outline-none hover:opacity-80 w-full"
                >
                  <span>Comprar mais</span>
                </button>
                <button
                  onClick={handleClearCart}
                  className="py-4 px-8 text-red-500 font-bold rounded  focus:outline-none hover:opacity-80 w-full"
                >
                  <span>Limpar carrinho</span>
                </button>
              </div>
            )}
          </div>

          {items.length > 0 && (
            <>
              <h1 className="font-bold text-xl mb-4">Opções de entrega</h1>

              {deliveryOptions.map((deliveryOption) => (
                <button
                  key={deliveryOption.option}
                  className={`text-left font-bold p-4 mb-2 border-2 focus:outline-none ${
                    deliveryOption.option === deliveryOptionActive
                      ? "text-white border-black bg-black"
                      : "text-black"
                  } transition-all duration-300`}
                  onClick={() => toggleOptionDelivery(deliveryOption)}
                >
                  {deliveryOption.label}
                </button>
              ))}
            </>
          )}
        </div>

        {items.length > 0 && (
          <div className=" w-full md:mx-4">
            <h1 className="font-bold text-xl mt-4">Informação de entrega</h1>
            {!deliveryOptionActive && (
              <p className="text-red-500">* Selecione uma opção de entrega</p>
            )}
            {error && (
              <p className="text-red-500">
                * Campos em destaque são obrigatórios
              </p>
            )}
            {deliveryOptionActive === "table" && (
              <div ref={formDelivery}>
                <FormBuilder
                  formRef={formRef}
                  handleFormSubmit={handleFormSubmit}
                >
                  <>
                    <Input
                      name="floorNumber"
                      className="outline-none focus:ring-2 focus:ring-black focus:border-transparen p-4 mt-4 bg-gray-100 w-full rounded-lg transition-all duration-300"
                      placeholder="Numero da mesa"
                      type="number"
                      autoComplete={"off"}
                    />
                    <TextArea name="obs" placeholder="Observações..." />
                  </>
                </FormBuilder>
              </div>
            )}
            {deliveryOptionActive === "take-away" && (
              <div ref={formDelivery}>
                <FormBuilder
                  formRef={formRef}
                  handleFormSubmit={handleFormSubmit}
                >
                  <>
                    <Input
                      name="federalDoc"
                      className="outline-none focus:ring-2 focus:ring-black focus:border-transparen p-4 mt-4 bg-gray-100 w-full rounded-lg transition-all duration-300"
                      placeholder="CPF (opcional)"
                      type="number"
                      autoComplete={"off"}
                    />
                    <Input
                      name="name"
                      className="outline-none focus:ring-2 focus:ring-black focus:border-transparen p-4 mt-4 bg-gray-100 w-full rounded-lg transition-all duration-300"
                      placeholder="Seu nome"
                      type="text"
                      autoComplete={"off"}
                    />
                    <Input
                      name="phone"
                      className="outline-none focus:ring-2 focus:ring-black focus:border-transparen p-4 mt-4 bg-gray-100 w-full rounded-lg transition-all duration-300"
                      placeholder="Seu telefone"
                      type="tel"
                      autoComplete={"off"}
                    />

                    <Input
                      label="Data e hora de entrega"
                      name="deliveryDateTime"
                      className="outline-none focus:ring-2 focus:ring-black focus:border-transparen p-4 bg-gray-100 w-full rounded-lg transition-all duration-300"
                      placeholder="Data de entrega"
                      type="datetime-local"
                      autoComplete={"off"}
                    />

                    <TextArea name="obs" placeholder="Observações..." />
                    <>
                      <h1 className="font-bold text-xl my-4">
                        Formas de pagamento
                      </h1>

                      {paymentOptions.map((paymentOption) => (
                        <button
                          type="button"
                          key={paymentOption.option}
                          className={`text-left font-bold p-4 mb-2 border-2 focus:outline-none ${
                            paymentOption.option === paymentOptionActive
                              ? "text-white border-black bg-black"
                              : "text-black"
                          } transition-all duration-300`}
                          onClick={() => toggleOptionPayment(paymentOption)}
                        >
                          {paymentOption.label}
                        </button>
                      ))}

                      {paymentOptionActive === "money" && (
                        <Input
                          autoFocus={true}
                          name="change"
                          placeholder="Troco para ..."
                          className="outline-none focus:ring-2 focus:ring-black focus:border-transparen p-4 mt-4 bg-gray-100 w-full rounded-lg transition-all duration-300"
                          autoComplete="off"
                          type="number"
                        />
                      )}
                    </>
                  </>
                </FormBuilder>
              </div>
            )}
            {deliveryOptionActive === "delivery" && (
              <div ref={formDelivery}>
                <FormBuilder
                  formRef={formRef}
                  handleFormSubmit={handleFormSubmit}
                  onChange={saveDataForm}
                >
                  <>
                    <Address>
                      <TextArea name="obs" placeholder="Observações..." />
                    </Address>
                    <Input
                      label="Data e hora de entrega"
                      name="deliveryDateTime"
                      className="outline-none focus:ring-2 focus:ring-black focus:border-transparen p-4 bg-gray-100 w-full rounded-lg transition-all duration-300"
                      placeholder="Data de entrega"
                      type="datetime-local"
                      autoComplete={"off"}
                    />
                    <>
                      <h1 className="font-bold text-xl my-4">
                        Formas de pagamento
                      </h1>

                      {paymentOptions.map((paymentOption) => (
                        <button
                          type="button"
                          key={paymentOption.option}
                          className={`text-left font-bold p-4 mb-2 border-2 focus:outline-none ${
                            paymentOption.option === paymentOptionActive
                              ? "text-white border-black bg-black"
                              : "text-black"
                          } transition-all duration-300`}
                          onClick={() => toggleOptionPayment(paymentOption)}
                        >
                          {paymentOption.label}
                        </button>
                      ))}

                      {paymentOptionActive === "money" && (
                        <Input
                          autoFocus={true}
                          name="change"
                          placeholder="Troco para ..."
                          className="outline-none focus:ring-2 focus:ring-black focus:border-transparen p-4 mt-4 bg-gray-100 w-full rounded-lg transition-all duration-300"
                          autoComplete="off"
                          type="number"
                        />
                      )}
                    </>
                  </>
                </FormBuilder>
              </div>
            )}

            {deliveryOptionActive && paymentOptionActive && (
              <div className="w-full mx-auto flex justify-between my-4 bottom-0 bg-white">
                <button
                  onClick={() => formRef?.current?.submitForm()}
                  className="bg-green-500 py-4 px-8 text-white font-bold rounded  focus:outline-none hover:opacity-80 w-full"
                >
                  <span>Confirmar</span>
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export { Cart };
