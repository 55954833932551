function useCurrency() {
  return {
    format: (value: number) =>
      new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(value),
  };
}

export { useCurrency };
