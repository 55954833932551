import React, { memo } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useCurrency } from "../../hooks/curreny.hook";
import { Image } from "../../components/Image";

type Product = {
  _id: string;
  price: number;
  name: string;
  image: string;
  description: string;
};
interface ChildrenPropsComponent {
  item: Product;
}

const CardProductComponent: React.FC<ChildrenPropsComponent> = ({ item }) => {
  const { store_id } = useParams<{ store_id: string }>();
  const { push } = useHistory();
  const currency = useCurrency();

  function formatURL(name: string) {
    let url = name.replace(/ /gi, "-");
    url = url.replace(/\//gi, "");
    url = url.replace(/--/gi, "-");
    url = url.replace(/\./gi, "");
    url = url.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    return url.toLowerCase();
  }

  return (
    <a
      href={`/${store_id}/product/${formatURL(item.name)}`}
      key={item._id}
      title={`Ver ${item.name}`}
      onClick={(e) => {
        e.preventDefault();
        push(`/${store_id}/product/${formatURL(item.name)}`, {
          product_id: item._id,
        });
      }}
      className="cursor-pointer  md:w-6/12	hover:opacity-90 transition-opacity duration-500"
    >
      <div className="hover:shadow-lg flex w-auto my-4 mx-4 bg-white border rounded-lg overflow-hidden">
        {item.image && (
          <div className="w-1/3 max-auto text-center">
            <Image name={item.image} alt={item.name} />
          </div>
        )}
        <div className="w-full p-4">
          <h1 className="text-gray-900 font-bold text-1xl">{item.name}</h1>
          <p
            className="mt-1 text-gray-600 text-sm truncate"
            dangerouslySetInnerHTML={{ __html: item.description }}
          />
          <div className="flex item-center justify-between mt-1">
            <h1 className="text-gray-700 font-bold text-xl">
              {currency.format(item.price)}
            </h1>
          </div>
        </div>
      </div>
    </a>
  );
};

const CardProduct = memo(CardProductComponent);
export { CardProduct };
