import React, { useEffect, useState } from "react";

import { Link, useHistory, useLocation, useParams } from "react-router-dom";

import { useDataStorage } from "../../hooks/datalstorage.hook";
import { useCurrency } from "../../hooks/curreny.hook";

import { MdClose, MdAdd, MdRemove } from "react-icons/md";
import { useFetch } from "../../hooks/fetch.hook";
import { Image } from "../../components/Image";
import { useToasts } from "react-toast-notifications";

type TypeProduct = {
  _id: string;
  price: number;
  name: string;
  image: string;
  description: string;
  qty: number;
};

const Product: React.FC = () => {
  const history = useHistory();
  const dataStorage = useDataStorage("cart");
  const currency = useCurrency();
  const { addToast } = useToasts();
  const { id, store_id } = useParams<{ id: string; store_id: string }>();
  const { state } = useLocation<{ product_id?: string }>();

  const { data } = useFetch<TypeProduct>(`product/${state?.product_id || id}`);
  const [item, setItem] = useState(data as TypeProduct);

  useEffect(() => {
    if (data) {
      document.title = `${data.name}`;
      setItem({ ...data, qty: 1 });
    }
  }, [data]);

  function handleAddQtyItem() {
    setItem({ ...item, qty: item.qty + 1 });
  }

  function handleRemoveQtyItem() {
    if (item.qty > 1) setItem({ ...item, qty: item.qty - 1 });
  }

  async function handleAddProductInCart(item: any) {
    const items = ((await dataStorage.find("items")) as any[]) || [];
    await dataStorage.update("items", [...items, { ...item }]);
    addToast(`${item.name} adicionado ao seu carrinho com sucesso.`, {
      appearance: "success",
      autoDismiss: true,
    });
    history.push(`/${store_id}`);
  }

  if (!item) return <p className="text-center p-4">Carregando produto...</p>;

  return (
    <>
      <div className="fixed md:inset-x-0 w-full md:max-w-lg mx-auto flex justify-between p-4 top-0  ">
        <Link
          to={`/${store_id}`}
          className="bg-white h-12 w-12 text-black flex flex-row justify-center items-center font-bold rounded-full  focus:outline-none hover:opacity-80"
        >
          <MdClose size={24} />
        </Link>
      </div>
      <div className="container mx-auto px-4">
        {item.image ? (
          <div className="max-w-md mx-auto">
            <Image
              className="object-center mx-auto md:max-w-xs"
              name={item?.image}
              alt={item.name}
            />
          </div>
        ) : (
          <div className="h-20"></div>
        )}
        <h1 className="font-bold py-4 md:max-w-md md:mx-auto text-4xl ">
          {item.name}
        </h1>
        {item.description && (
          <div className="mb-20 md:max-w-md md:mx-auto text-justify text-sm">
            <p dangerouslySetInnerHTML={{ __html: item.description }} />
          </div>
        )}
      </div>
      <div className="fixed md:inset-x-0 w-full md:max-w-md mx-auto flex justify-between p-4 bottom-0 bg-white ">
        <div className="flex justify-between items-center content-center bg-white w-full">
          <button
            disabled={item.qty <= 1}
            onClick={handleRemoveQtyItem}
            className={`rounded-full w-12 h-12 bg-red-500  font-bold text-white focus:outline-none hover:opacity-80 flex flex-row items-center justify-center ${
              item.qty <= 1 && "opacity-50"
            }t
             `}
          >
            <MdRemove size={24} />
          </button>
          <span className="font-bold text-2xl">{item.qty}</span>
          <button
            onClick={handleAddQtyItem}
            className="rounded-full  w-12 h-12 bg-green-500 font-bold text-white focus:outline-none hover:opacity-80 flex flex-row items-center justify-center"
          >
            <MdAdd size={24} />
          </button>
        </div>
        <button
          onClick={() => handleAddProductInCart(item)}
          className="flex flex-row justify-between items-center bg-black ml-5 py-2 px-8 text-white font-bold rounded  focus:outline-none hover:opacity-80 w-full"
        >
          <span>Adicionar {currency.format(item.price * item.qty)}</span>
        </button>
      </div>
    </>
  );
};

export { Product };
