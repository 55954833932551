import React from "react";

import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";

interface ChildrenPropsComponent {
  formRef: React.RefObject<FormHandles>;
  handleFormSubmit: (props: any) => void;
  onChange?: any;
}

const FormBuilder: React.FC<ChildrenPropsComponent> = ({
  formRef,
  handleFormSubmit,
  children,
  ...rest
}) => {
  const childrenWithExtraProp = React.Children.map(children, (child: any) =>
    React.cloneElement(child, { formRef })
  );

  return (
    <Form ref={formRef} onSubmit={handleFormSubmit} {...rest}>
      {childrenWithExtraProp}
    </Form>
  );
};

export { FormBuilder };
