import axios from "axios";

const api = axios.create({
  baseURL:
    process.env.NODE_ENV === "production"
      ? "https://node50164-csbase.jelastic.saveincloud.net/api/v1/csmenu"
      : "http://localhost:3030/api/v1/csmenu",
  headers: {
    "x-service": "csmenu",
  },
});

api.interceptors.response.use(
  (response) => response,
  ({ response }) => {
    if (response) {
      const { error } = response.data;
      if (error.includes("Pedido não")) console.log("Pedido não encontrado");
      else alert(error);
    } else {
      alert("Error desconhecido");
    }

    throw response;
  }
);

export default api;
