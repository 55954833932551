import React from "react";
import { useHistory, useLocation, useParams } from "react-router";
import { Logo } from "../Logo";

interface ChildrenPropsComponent {
  companyName: string;
  address: {
    address: "Av. Ernani Cardoso";
    city: "Rio de Janeiro";
    complement: "BLOCO 6 AP 402";
    district: "CASCADURA";
    federative_unit: "RJ";
    location: {
      coordinates: [-43.3354446, -22.8826178];
      _id: "601ebc418c611462d568b744";
      type: "Point";
    };
    number: "237";
    postal_code: 21310310;
  };
  isOpen: boolean;
  image: string;
}

const Header: React.FC<ChildrenPropsComponent> = ({
  companyName,
  address,
  image,
  isOpen,
}) => {
  const { store_id } = useParams<{ store_id: string }>();
  const history = useHistory();
  const location = useLocation();

  return (
    <div className="font-sans container mx-auto">
      <div className="text-center max-w-2xl mx-auto">
        {image ? (
          <Logo name={image} />
        ) : (
          <h1 className="text-4xl text-center pt-8 pb-2">{companyName}</h1>
        )}

        <h2 className="text-xl text-center mt-4 mx-8 ">
          {address.address}, {address.number} - {address.district} -{" "}
          {address.city}/{address.federative_unit}
        </h2>

        {/* <p className="text-center">Tempo de Entrega: de 45 a 60 min</p>
        <p className="text-center">Tempo de Retirada: 25 min</p> */}
        {/* {isOpen && (
          <div className="bg-green-400 p-2 my-4 mx-auto max-w-xs rounded">
            <h1 className="text-center text-white font-bold">Aberto agora</h1>
          </div>
        )}
        {!isOpen && (
          <div className="bg-red-400 p-2 my-4 mx-auto max-w-xs rounded">
            <h1 className="text-center text-white font-bold">Fechado agora</h1>
          </div>
        )} */}
        <div className="flex justify-between mx-auto max-w-xl px-4">
          <button
            onClick={() => history.push(`/${store_id}`)}
            className={`w-full ml-2 ${
              !location.pathname.includes("my-orders") ? "bg-black" : "bg-white"
            } p-2 my-4 rounded focus:outline-none text-center ${
              !location.pathname.includes("my-orders")
                ? "text-white"
                : "text-black"
            } font-bold`}
          >
            Loja
          </button>
          <button
            onClick={() => history.push(`/${store_id}/my-orders`)}
            className={`w-full ml-2 ${
              location.pathname.includes("my-orders") ? "bg-black" : "bg-white"
            } p-2 my-4 rounded focus:outline-none text-center ${
              location.pathname.includes("my-orders")
                ? "text-white"
                : "text-black"
            } font-bold`}
          >
            Meus pedidos
          </button>
        </div>
      </div>
    </div>
  );
};

export { Header };
