import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { Header, ProductsList, CartButton } from "../../components";
import { useFetch } from "../../hooks/fetch.hook";

import { groupBy, keys, orderBy, map, filter } from "lodash";

type Product = {
  _id: string;
  price: number;
  name: string;
  image: string;
  description: string;
};

const HomeScreen: React.FC = () => {
  const { store_id } = useParams<{ store_id: string }>();
  const { data: store, error } = useFetch(`store/${store_id}`);
  const { data: products } = useFetch<Product[]>("product");
  const [categories, setCategories] = useState<string[]>([]);
  const [productList, setProductList] = useState<{
    [x: string]: Product[];
  }>({});

  const anchorsCategories = useRef<HTMLHeadingElement[] | null[]>([]);

  const handlerNavigateToCategory = useCallback((indexCategory: number) => {
    anchorsCategories.current[indexCategory]?.scrollIntoView({
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    const productsExceptFees = filter(products, (product) =>
      !product.name.toLowerCase().includes("taxa")
    );
   
    const productsOrderByCategory = groupBy(
      productsExceptFees,
      "category.name"
    ) as {
      [x: string]: Product[];
    };
    const getCategoriesAndOrderByName = orderBy(keys(productsOrderByCategory));
    const categories = map(getCategoriesAndOrderByName, (category) =>
      category.toLowerCase()
    ) as string[];

    setCategories(categories);
    setProductList(productsOrderByCategory);
  }, [products]);

  useEffect(() => {
    document.title = `${!store ? "Loja não encontrada." : store.fantasy_name}`;
  }, [store]);

  if (!store)
    return (
      <>
        <h1 className="text-center text-lg pt-8">
          {error ? `Não foi possível carregar loja 😞` : `Carregando loja...`}
        </h1>
        {error && (
          <>
            <p className="text-center p-4">Mas estou tentando</p>
            <p className="text-center p-2 animate-ping">⌛</p>
          </>
        )}
        <h1 className="text-center text-sm mt-4">
          &copy; {new Date().getFullYear()} - Centre Soluções
        </h1>
      </>
    );

  return (
    <>
      <Header
        companyName={store.fantasy_name}
        address={store.location}
        isOpen={store.is_open}
        image={store.image}
      />
      <div className="max-w-2xl mx-auto py-4 text-center">
        <h1 className="text-2xl font-bold antialiased">Categorias</h1>
      </div>
      <div className="flex flex-col sm:flex-row sm:justify-start sm:flex-wrap max-w-2xl mx-auto mb-4">
        {map(categories, (category, index) => (
          <React.Fragment key={index}>
            <button
              className={`border sm:max-w-sm ml-2 p-4 my-1 rounded-full text-sm capitalize hover:bg-gray-100 hover:text-gray-700 text-gray-500 focus:outline-none text-center font-bold`}
              onClick={() => handlerNavigateToCategory(index)}
            >
              {category}
            </button>
          </React.Fragment>
        ))}
      </div>
      {!products ? (
        <p className="text-center text-lg pt-8">Carregando...</p>
      ) : (
        <div className="max-w-screen-lg py-4 mx-auto">
          {map(categories, (category, index) => (
            <React.Fragment key={index}>
              <h1
                className="ml-4 mt-4 text-2xl font-bold antialiased capitalize"
                ref={(el) => (anchorsCategories.current[index] = el)}
                key={index}
              >
                {category}
              </h1>
              <ProductsList items={productList[category.toUpperCase()]} />
            </React.Fragment>
          ))}
        </div>
      )}
      <CartButton />
    </>
  );
};

export { HomeScreen };
