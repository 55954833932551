import { FormHandles } from "@unform/core";
import React from "react";
import * as Yup from "yup";

export async function createOrderByDelivery(
  formRef: React.RefObject<FormHandles>,
  data: any
) {
  // eslint-disable-next-line no-throw-literal
  if (!formRef) throw "FormRef not undefine value";

  try {
    formRef?.current?.setErrors({});

    const objShap = {
      name: Yup.string().min(3).required(),
      phone: Yup.string().min(10).required(),
      cep: Yup.string().min(8).max(8).required(),
      street: Yup.string().required(),
      number: Yup.string(),
      // area: Yup.string().required(),
      neighborhood: Yup.string().required(),
      city: Yup.string().required(),
      state: Yup.string().required(),
    };

    if (data.paymentOptionActive === "money")
      Object.assign(objShap, { change: Yup.number().min(1).required() });

    const schema = Yup.object().shape(objShap);
    await schema.validate(data, {
      abortEarly: false,
    });
    // Validation passed
    return data;
  } catch (err) {
    if (err instanceof Yup.ValidationError) {
      const validationErrors: { [x: string]: any } = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path as string] = error.message;
        });
        formRef?.current?.setErrors(validationErrors);
      }
      return { erros: validationErrors };
    }
  }
}

export async function createOrderByTakeAway(
  formRef: React.RefObject<FormHandles>,
  data: any
) {
  // eslint-disable-next-line no-throw-literal
  if (!formRef) throw "FormRef not undefine value";

  try {
    formRef?.current?.setErrors({});
    const objShap = {
      name: Yup.string().min(3).required(),
      phone: Yup.string().min(10).required(),
    };

    if (data.paymentOptionActive === "money")
      Object.assign(objShap, { change: Yup.number().min(1).required() });

    const schema = Yup.object().shape(objShap);
    await schema.validate(data, {
      abortEarly: false,
    });
    // Validation passed
    return data;
  } catch (err) {
    if (err instanceof Yup.ValidationError) {
      const validationErrors: { [x: string]: any } = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path as string] = error.message;
        });
        formRef?.current?.setErrors(validationErrors);
      }
      return { erros: validationErrors };
    }
  }
}

export async function createOrderByTable(
  formRef: React.RefObject<FormHandles>,
  data: any
) {
  // eslint-disable-next-line no-throw-literal
  if (!formRef) throw "FormRef not undefine value";

  try {
    formRef?.current?.setErrors({});

    const schema = Yup.object().shape({
      floorNumber: Yup.string().min(1).required(),
    });
    await schema.validate(data, {
      abortEarly: false,
    });
    // Validation passed
    return data;
  } catch (err) {
    if (err instanceof Yup.ValidationError) {
      const validationErrors: { [x: string]: any } = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path as string] = error.message;
        });
        formRef?.current?.setErrors(validationErrors);
      }
      return { erros: validationErrors };
    }
  }
}
