import React, { useEffect, useState } from "react";
import { MdShoppingCart } from "react-icons/md";
import { useHistory, useParams } from "react-router";
import { useDataStorage } from "../../hooks/datalstorage.hook";

import { filter } from "lodash";

interface ChildrenComponentProps {}

const CartButton: React.FC<ChildrenComponentProps> = () => {
  const history = useHistory();
  const { store_id } = useParams<{ store_id: string }>();
  const dataStorage = useDataStorage("cart");

  const [items, setItems] = useState<any[]>([]);

  useEffect(() => {
    const loaderItems = async () => {
      let items = ((await dataStorage.find("items")) || []) as any[];
      items = filter(
        items,
        (product) => !product.name.toLowerCase().includes("taxa")
      );
      setItems(items);
    };
    loaderItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (items.length === 0) return <></>;

  return (
    <div className="fixed bottom-0 right-0 p-4">
      <div className="text-center bg-black text-white rounded-lg mb-2">
        {items.length}
      </div>
      <button
        onClick={() => history.push(`/${store_id}/cart`)}
        className={`rounded-full w-12 h-12 bg-black  font-bold text-white focus:outline-none hover:opacity-80 flex flex-row items-center justify-center`}
      >
        <MdShoppingCart color={"#FFF"} size={18} />
      </button>
    </div>
  );
};

export { CartButton };
