import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { CartButton, Header } from "../../components";
import { CardOrder } from "../../components/CardOrder";
import { useDataStorage } from "../../hooks/datalstorage.hook";
import { useFetch } from "../../hooks/fetch.hook";

interface ChildrenComponentProps {}

const Orders: React.FC<ChildrenComponentProps> = () => {
  const { store_id } = useParams<{ store_id: string }>();
  const { data: store, error } = useFetch(`store/${store_id}`);
  const orderStore = useDataStorage("orders");

  const [orders, setOrders] = useState<any[]>([]);

  useEffect(() => {
    document.title = `${
      !store ? "Loja não encontrada." : `${store.fantasy_name} - Meus pedidos`
    }`;
    const loaderOrders = async () => {
      const orders = ((await orderStore.find("list")) as any[]) || [];
      setOrders(orders);
    };
    loaderOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store]);

  if (!store)
    return (
      <>
        <h1 className="text-center text-lg pt-8">
          {error ? `Não foi possível carregar loja 😞` : `Carregando loja...`}
        </h1>
        {error && (
          <>
            <p className="text-center p-4">Mas estou tentando</p>
            <p className="text-center p-2 animate-ping">⌛</p>
          </>
        )}
        <h1 className="text-center text-sm mt-4">
          &copy; {new Date().getFullYear()} - Centre Soluções
        </h1>
      </>
    );

  return (
    <>
      <Header
        companyName={store?.fantasy_name}
        address={store?.location}
        isOpen={store?.is_open}
        image={store.image}
      />
      <div className="flex max-w-screen-lg my-0 mx-auto flex-col md:flex-row md:flex-wrap">
        {orders.length > 0 &&
          orders?.reverse()?.map((order) => (
            <React.Fragment key={order._id}>
              <CardOrder order={order} store={store} />
            </React.Fragment>
          ))}
        {orders.length === 0 && (
          <h1 className="text-center p-8 m-auto text-red-500">Sem pedidos</h1>
        )}
      </div>
      <CartButton />
    </>
  );
};

export { Orders };
