import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { HomeScreen } from "../screens/Home";
import { Product } from "../screens/Product";
import { Cart } from "../screens/Cart";
import { Orders } from "../screens/Orders";

const StoreNotFound: React.FC<any> = () => {
  const emojis = [
    "😬",
    "🥺",
    "😣",
    "😖",
    "😫",
    "😩",
    "🥱",
    "😤",
    "😮",
    "😱",
    "😨",
    "😰",
    "😯",
    "😦",
    "😧",
    "😢",
    "😥",
  ];

  useEffect(() => {
    document.title = "Centre Soluções - Sua loja rapida na internet.";
  }, []);

  return (
    <>
      <h1 className="text-center text-lg pt-8">
        Não encontrei a loja solicitada
      </h1>
      <p className="text-center text-lg">
        {emojis[Math.floor(Math.random() * (emojis.length - 0)) + 0]}
      </p>
    </>
  );
};

const Routes: React.FC<any> = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={StoreNotFound} />
        <Route exact path="/:store_id" component={HomeScreen} />
        <Route exact path="/:store_id/my-orders" component={Orders} />
        <Route exact path="/:store_id/product/:id" component={Product} />
        <Route exact path="/:store_id/cart" component={Cart} />
      </Switch>
    </Router>
  );
};

export { Routes };
