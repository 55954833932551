function useDate() {
  return {
    format: (value: Date | string) =>
      new Intl.DateTimeFormat("pt-BR", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      }).format(new Date(value)),
  };
}

export { useDate };
