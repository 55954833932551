import React from "react";
import { CardProduct } from "../CardProduct";

type Product = {
  _id: string;
  price: number;
  name: string;
  image: string;
  description: string;
};
interface ChildrenPropsComponent {
  items: Product[];
}

const ProductsList: React.FC<ChildrenPropsComponent> = ({ items }) => {
  return (
    <div className="flex max-w-screen-lg my-0 mx-auto flex-col md:flex-row md:flex-wrap">
      {items.map((item) => {
        return <CardProduct key={item._id} item={item} />;
      })}
    </div>
  );
};
export { ProductsList };
