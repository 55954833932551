import React from "react";
import { useCurrency } from "../../hooks/curreny.hook";
import { useDate } from "../../hooks/dates.hook";
import { useFetch } from "../../hooks/fetch.hook";

interface ChildrenComponentProps {
  order: any;
  store: any;
}

const StatusOrder: { [x: string]: any } = {
  opened: "Em aberto",
  transit: "Em transito",
  delivered: "Entregue",
  closed: "Fechado",
  rejected: "Rejeitado",
  canceled: "Cancelado",
  done: "Concluido",
  accept: "Aceito",
};

const CardOrder: React.FC<ChildrenComponentProps> = ({ order, store }) => {
  const currency = useCurrency();
  const date = useDate();
  const { data } = useFetch(`/order/${order._id}`);

  if (!order) return <></>;

  function handleOpenChatWhatsApp(
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) {
    e.preventDefault();

    const message = `Olá, como vai? Gostaria de saber qual o status do meu pedido *N° ${
      order.number
    }* foi aberto em *${date.format(order.dates.created)}* \n Obrigado!`;

    window.open(
      `https://api.whatsapp.com/send?phone=55${store.phone}&text=${message}`
    );
  }

  return (
    <>
      <div className="rounded shadow w-full  p-4 mb-4">
        <h1 className="text-xl font-bold">Seu pedido #{order.number}</h1>
        <div className="w-full my-2">
          <div>
            <small className="font-bold">Pedido aberto:</small>
            <small> {date.format(order.dates.created)}</small>
          </div>
          {data?.dates?.done && (
            <>
              <small className="font-bold">Pedido concluido:</small>
              <small> {date.format(data.dates.done)}</small>
            </>
          )}
          {data?.dates?.canceled && (
            <>
              <small className="font-bold">Pedido cancelado:</small>
              <small> {date.format(data.dates.canceled)}</small>
            </>
          )}
          <div>
            <small className="font-bold">Status:</small>
            <small> {StatusOrder[data?.status || order.status]}</small>
          </div>
        </div>
        <div className="flex justify-between">
          <div className="w-9/12">
            {order.items.map((item: any, index: number) => (
              <span key={index.toString()} className="block my-4">
                <span className="bg-gray-200 rounded p-1 text-center mr-2">
                  {item.qty}x{" "}
                </span>
                {item?.name?.length > 30
                  ? item?.name?.substr(0, 28) + "..."
                  : item?.name}
              </span>
            ))}

            <span>Total</span>
          </div>
          <div className="text-green-600">
            {order.items.map((item: any, index: number) => (
              <span key={index.toString()} className="block font-bold my-4">
                {currency.format(item.price)}
              </span>
            ))}

            <span className="font-bold">
              {currency.format(order.summary.amount)}
            </span>
          </div>
        </div>
        <button
          onClick={handleOpenChatWhatsApp}
          className="bg-green-600 w-full p-3 my-6 text-white font-bold rounded-lg"
        >
          WhatsApp
        </button>
      </div>
    </>
  );
};
export { CardOrder };
